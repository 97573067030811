.message.-user {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 90%;

  img {
    max-height: 50px;
  }

  .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    min-width: 28px;
    border-radius: 50%;
    background-color: var(--main-text-color);
    color: var(--main-bg-color);
    font-size: 0.6rem;
    font-weight: 700;
    padding: 0.25rem;
  }

  .message-content {
    white-space: pre-line;
  }
}
