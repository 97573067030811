.messages-window {
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 85dvh;
  flex-grow: 1;
}

.-scrolling .messages-window {
  padding-bottom: 105px;
}

.messages-window > div {
  width: 100%;
  padding: 1.5rem 0;
}

.messages-window > div:nth-child(even) {
  background: var(--conversation-response);
}

.messages-window > div:last-child {
  margin-bottom: 45px;
}

.messages-window .message {
  width: 100%;
  max-width: 610px;
  margin: 0 auto;
  line-height: 1.65rem;
  padding: 10px;
}

@media (min-width: 640px) {
  .messages-window .message {
    max-width: var(--center-column-width);
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .messages-window .message {
    max-width: 610px;
  }
}
