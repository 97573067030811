.conversation-controls {
  display: flex;
  margin: 0 auto;
  grid-gap: 0.5rem;
  justify-content: flex-end;
  width: 90%;

  .upload-btn,
  .select-files-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-text-color);
    border: 1px solid var(--button-border-color);
    background: transparent;
    grid-gap: 0.5rem;
    height: 1.8rem;
    font-size: 0.8rem;

    svg path {
      stroke: var(--main-text-color);
    }
  }
}

@media (min-width: 1024px) {
  .conversation-controls {
    width: 100%;
  }
}
