@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --stryker-gold-dark: #ffb500;
  --stryker-gold-dark-hover: #e19900; /* 10% 000 tint */
  --stryker-gold-dark-active: #d48800; /* 15% 000 tint */

  --button-height: 38px;
  --button-text-size: 15px;
  --button-border-radius: 12px;
  --button-dashed-hover: #5c5c5d;
  --button-border-color: white;
  --button-text-default: #010101;
  --button-text-gray: #8a8a8b;

  --button-danger-color: #ff453a;
  --button-danger-color-hover: #db3830;
  --button-active-color: var(--stryker-gold-dark);
  --button-active-color-hover: var(--stryker-gold-dark-hover);
  --button-active-color-active: var(--stryker-gold-dark-active);
  --button-grayed-color: #48484a;
  --button-grayed-color-hover: #48484a;
  --button-selected-bg-color: #fff;
  --button-selected-text-color: #000;

  --toast-close-button-bg-color: #3c3c43;
  --toast-close-button-text-color: #fff;

  --hyperlink-color: #007aff;

  --z-high-priority: 90;
  --z-med-priority: 80;
  --z-low-priority: 50;

  --font-body: InterVariable, sans-serif;
  --font-body-fallback: Inter, sans-serif;

  --center-column-width: 608px;

  /*  Needed for global toast notifications */
  --main-text-color: #fff;
  --notification-bg: #323235;
  --main-text-warning: var(--stryker-gold-dark);
  --main-accent-color: var(--stryker-gold-dark);

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--main-bg-color);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--collapse-control-bg);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  font-size: 16px;
  font-family: var(--font-body-fallback);
  font-feature-settings:
    'liga' 1,
    'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: var(--font-body) !important;
  }
}

.-theme-dark {
  --main-bg-color: #2c2c2e;
  --main-text-color-light: #9f9fa5;
  --main-accent-color-hover: #e5a72b;
  --main-danger-color: #ff453a;
  --main-danger-color-hover: #db3830;
  --main-text-color-disabled: #787880;
  --card-bg-color: #39393d;
  --card-bg-color-hover: #5b5b5f;

  --context-button-bg: #3d3d42;
  --context-button-bg-hover: #5b5b5f;

  --sidebar-bg-color: #1c1c1e;

  --notice-bg: #444449;
  --notice-desc: #a8a8b0;

  --main-input-bg-color: #787880;
  --main-input-text-color: #fff;

  --context-button-selected: #2fd157;
  --context-button-selected-hover: #4ab166;

  --conversation-response: #323235;

  --border-radius: 12px;
  --input-border-radius: 24px;
  --input-background-color: #444448;
  --input-background-color-hover: #3e3e40;
  --input-placeholder-color: #787880;
  --input-text-color: #fff;
  --input-height: 3em;

  --gpt-model-selector: rgba(68, 68, 72, 0.95);

  --radio-background-color: #444448;
  --radio-circle-color: #fff;

  --modal-background-color: #2c2c2e;
  --modal-button-background: #3b3b3e;
  --modal-button-background-hover: #252527;
  --modal-scrollbar-foreground: #3b3b3e;
  --modal-scrollbar-background: #2c2c2e;
  --settings-menu-border: #545458;
  --settings-sign-out: #ff453a;

  --file-dropzone: rgba(120, 120, 128, 0.32);
  --file-dropzone-border-color: #fff;
  --file-dropzone-success: #2d553a;
  --file-dropzone-error: #613332;

  --avatar-bg: #fff;
  --avatar-text-color: #000;

  --collapse-control-color: #fff;

  --markdown-editor-border: #c7c7c7;
  --markdown-code-bg: #1e1e1c;
  --markdown-code-copy-bg: #48484a;
  --markdown-table-border-radius: 13px;

  --file-checkbox-border: #d9d9d9;
  --file-text-hover: #65656b;
  --file-text-unavailable-hover: #35353b;
  --file-text-available: #95959b;
  --file-text-unavailable: #55555b;

  --conversation-history-hover-color: #424243;
  --conversation-history-selected-color: #5e5e60;

  --textarea-placeholder: rgba(235, 235, 245, 0.6);

  --info-text: rgba(235, 235, 245, 0.6);

  --scrolling-settings-shadow-color: rgba(4, 4, 6, 0.4);
  --scrolling-settings-model-button-bg: rgba(209, 209, 214, 0.24);
  --scrolling-question-input-shadow-color: rgba(44, 46, 46, 0.8);
}

.-theme-light {
  --button-dashed-hover: #e5e5ea;
  --button-border-color: #808086;

  --conversation-response: #dcdce2;

  --card-bg-color: #e5e5ea;

  --main-bg-color: #e5e5ea;
  --main-input-bg-color: #d4d4d9;
  --main-input-text-color: #787878;
  --main-text-color: #2c2c2e;

  --sidebar-bg-color: #f2f2f2;

  --modal-background-color: #e1e1e7;
  --modal-button-background: #c8c8cf;
  --modal-button-background-hover: #d0d0d6;
  --settings-menu-border: #545458;

  --input-background-color: #d4d4d9;
  --input-background-color-hover: #d1d1d6;
  --input-text-color: #3c3c4399;

  --file-dropzone: #d4d4d9;
  --file-dropzone-border-color: #787878;

  --radio-background-color: #fff;
  --radio-circle-color: #111;

  --avatar-bg: #d1d1d6;

  --context-button-bg: #d7d7dd;
  --context-button-bg-hover: #cbcbcf;
  --context-button-selected: #2ec04f;
  --context-button-selected-hover: #43a15c;
  --context-button-bg-disabled: #5d5d66;

  --collapse-control-color: #000;

  --notification-bg: #e5e5ea;

  --notice-bg: #dcdce2;
  --notice-desc: #a8a8b0;

  --markdown-code-bg: #fafafa;
  --markdown-code-copy-bg: #e5e5ea;

  --file-checkbox-border: #000;
  --file-text-hover: #55555b;
  --file-text-available: #75757b;
  --file-text-unavailable: #b5b5bb;
  --file-text-unavailable-hover: #a5a5ab;

  --conversation-history-hover-color: #d1d1d6;
  --conversation-history-selected-color: #bbbbc0;

  --info-text: rgba(60, 60, 67, 0.6);

  --textarea-placeholder: rgba(60, 60, 67, 0.6);

  --scrolling-settings-shadow-color: rgba(4, 4, 6, 0.2);
  --scrolling-settings-model-button-bg: rgba(209, 209, 214, 0.44);
  --scrolling-question-input-shadow-color: rgba(229, 229, 234, 0.8);

  --modal-scrollbar-foreground: #d4d4d9;
  --modal-scrollbar-background: #e1e1e7;
}

body {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

h1 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

ul {
  list-style: disc;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  margin: 0.5rem;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 400 900;
  font-display: swap;
  src: url('fonts/InterVariable.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/InterRegular.woff2?v=4.0') format('woff2');
}
