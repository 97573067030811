.contexts {
  display: flex;
  align-items: center;
  height: 100vh;
  font-size: 1em;

  .context-list {
    display: grid;
    align-content: center;
    justify-content: center;
    width: 100%;
    > div {
      margin: 0.5em auto;
      width: 100%;
      min-width: 600px;
      > div {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  button {
    width: 50vw;
  }
}
